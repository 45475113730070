import { SplitFactory } from '@splitsoftware/splitio'
import { Attributes } from '@splitsoftware/splitio/types/splitio'
import { publicEnv } from './constants/publicEnv'
import { FeatureFlag, featureFlags } from './flags'
import { isSSR } from './utils/ssr'

type FeatureFlagOverrides = Record<
  FeatureFlag,
  'on' | 'off' | (string & {}) // (string & {}) allows us to any string value, while still getting auto-completion for 'on' and 'off'
>

const SDK_CONFIG_OBJECT: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey:
      process.env.REACT_APP_SPLIT_IO_MOCKS === 'true' ||
      process.env.NODE_ENV === 'test'
        ? 'localhost'
        : isSSR
        ? String(process.env.SPLITIO_API_KEY)
        : String(publicEnv.REACT_APP_SPLITIO_API_KEY),
    key: 'Layup',
    trafficType: 'USER',
  },
  debug: process.env.REACT_APP_FEATURE_FLAG_DEBUG === 'true',
  storage: isSSR ? undefined : { type: 'LOCALSTORAGE' as 'LOCALSTORAGE' },
  features: isSSR
    ? undefined
    : process.env.REACT_APP_SPLIT_IO_MOCKS === 'true' ||
      process.env.NODE_ENV === 'test'
    ? ({
        ...Object.fromEntries(Object.keys(featureFlags).map(ff => [ff, 'on'])),
        // Override feature flag local mocks
        // featureFlagName: 'off',
        // kill-switch disables registrations, so we want this off by default
        // when running layup locally
        'layup-registration-kill-switch': 'off',
        'layup-environment-labels': 'off',
        'layup-live-scoring-scorecard': 'off',
        'layup-kill-login': 'off',
        'layup-mastercard-click-to-pay': 'off',
        'layup-optional-account-linking': 'off',
      } as FeatureFlagOverrides)
    : undefined,
}

export const splitFactory = SplitFactory(SDK_CONFIG_OBJECT)

export let splitUserKey: string | null = null
export const splitClient = splitFactory.client()
export let authedClient = splitClient

export const setupAuthedClient = (key: string) => {
  if (key !== splitUserKey) {
    splitUserKey = key
    authedClient = splitFactory.client(key)
  }
  return authedClient
}

export const featureEnabledWithConfig = (
  name: FeatureFlag,
  attributes: Attributes,
  hashedUserSub?: string,
) => {
  const treatmentWithConfig = isSSR
    ? // NodeJS
      splitClient.getTreatmentWithConfig(
        String(process.env.SPLITIO_CLIENT_NAME),
        name,
        attributes,
      )
    : // Browser
      (hashedUserSub ? authedClient : splitClient).getTreatmentWithConfig(
        name,
        attributes,
      )

  return treatmentWithConfig
}
