export const joinWords = (strings: string[]) => {
  if (strings.length === 1) {
    return strings[0]
  }
  const last = strings[strings.length - 1]
  const others = strings.slice(0, strings.length - 1)
  return others.join(', ').concat(` and ${last}`)
}

/**
 * Get the venue name and court name as a single formatted string.
 *
 * @param court Object that contains the court name and associated venue name
 * @returns String If both strings are non-empty, string is formatted as `${court.name} / ${court.venue.name}`. If one string is empty, returns the non-empty string. If both are empty, returns "N/A".
 */
export const venueCourtString = (court: {
  name: string
  venue: {
    name: string
  }
}) => {
  if (court.name === '' && court.venue.name === '') return 'N/A'
  if (court.name === '') return court.venue.name
  if (court.venue.name === '') return court.name

  return `${court.venue.name} / ${court.name}`
}

export const toTitleCase = (str: string) =>
  str[0]?.toUpperCase() + str.substring(1)?.toLowerCase()
